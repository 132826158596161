<template>
  <v-card :loading="cardLoading">
    <v-card-text>
      <v-form
        ref="customFieldsForm"
        v-model="valid"
        class="multi-col-validation ma-4"
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="12">
            <draggable
              v-model="customFields"
              v-bind="dragOptions"
              :disabled="!edit"
              :scroll-sensitivity="350"
              :force-fallback="true"
              @end="onDragEnd"
              @start="onDragStart"
            >
              <div
                v-for="(customField, index) in customFields"
                :id="`customFieldCard_${index}`"
                :key="`${index}--custom-field`"
              >
                <v-card outlined class="my-4 card--border-primary">
                  <v-card-title>
                    <div v-if="!edit">{{ customField.name }}</div>
                    <v-text-field
                      v-else
                      v-model="customField.name"
                      outlined
                      dense
                      :label="$t('name')"
                      :placeholder="$t('name')"
                      :rules="[validators.required, validators.whiteSpaceValidator]"
                      maxlength="50"
                      hide-details="auto"
                    >
                      <template v-if="edit" #prepend>
                        <v-icon class="cursor-pointer my-handle" color="primary">{{ icons.mdiDrag }}</v-icon>
                      </template>
                      <template #append-outer>
                        <v-tooltip top transition="scroll-y-transition" open-delay="150">
                          <template #activator="{ on, attrs }">
                            <v-btn color="error" x-small v-bind="attrs" @click="removeCustomField(index)" v-on="on">
                              <v-icon class="me-2" size="16">
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                              <span v-t="'delete'" />
                            </v-btn>
                          </template>
                          <span v-t="'delete'" class="text-xs" />
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-card-title>
                </v-card>
              </div>
            </draggable>
            <v-btn v-if="edit" v-t="'addCustomField'" color="primary" class="my-4" block @click="addCustomField" />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!edit" cols="12"
            ><v-btn color="primary" @click.prevent="edit = !edit">
              <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon> <span v-t="'edit'" /> </v-btn
          ></v-col>
          <v-col v-if="edit" cols="12">
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
              :loading="loading"
              :disabled="!valid || !(areDataChanged || areCustomFieldsOrderChanged)"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn v-t="'discard'" outlined color="secondary" @click="restoreData" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { mdiDotsVertical, mdiTrashCanOutline, mdiDrag, mdiPencilOutline } from '@mdi/js'
import { ref, onMounted, computed, nextTick } from '@vue/composition-api'
import { required, whiteSpaceValidator } from '@core/utils/validation'
import { useActions } from 'vuex-composition-helpers'
import draggable from 'vuedraggable'
import useDraggableOptions from '@/plugins/draggable'

export default {
  components: {
    draggable,
  },
  setup() {
    const { dragOptions, onDragStart, onDragEnd } = useDraggableOptions()
    const customFieldsForm = ref(null)
    const valid = ref(true)
    const loading = ref(false)
    const cardLoading = ref(false)
    const customFields = ref([])
    let frozenCustomFields = {}

    const edit = ref(false)

    const { getClientCustomFields, updateClientCustomFields } = useActions('staff', [
      'getClientCustomFields',
      'updateClientCustomFields',
    ])

    onMounted(async () => {
      cardLoading.value = true
      customFields.value = await getClientCustomFields()
      frozenCustomFields = Object.freeze(_.cloneDeep(customFields.value))
      cardLoading.value = false
    })

    const handleFormSubmit = async () => {
      const isFormValid = customFieldsForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      customFields.value = await updateClientCustomFields(customFields.value)
      frozenCustomFields = Object.freeze(_.cloneDeep(customFields.value))
      loading.value = false
      edit.value = false
    }

    const restoreData = () => {
      customFields.value = _.cloneDeep(frozenCustomFields)
      edit.value = false
    }

    const addCustomField = async () => {
      customFields.value.push({
        name: '',
      })
      nextTick(() => {
        const el = document.getElementById(`customFieldCard_${customFields.value.length - 1}`)
        el.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      })
    }

    const removeCustomField = (index) => {
      customFields.value.splice(index, 1)
    }

    const areDataChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(customFields.value, frozenCustomFields, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(frozenCustomFields, customFields.value, _.isEqual))
      )
    })

    const areCustomFieldsOrderChanged = computed(() => {
      // eslint-disable-next-line array-callback-return
      const customFieldsOrder = customFields.value.map((cf) => {
        if (Object.hasOwnProperty.bind(cf)('order')) return cf.order
      })

      return !customFieldsOrder.every((el, index) => {
        return el === frozenCustomFields[index]?.order
      })
    })

    return {
      handleFormSubmit,
      restoreData,
      addCustomField,
      removeCustomField,
      onDragStart,
      onDragEnd,

      areDataChanged,
      areCustomFieldsOrderChanged,

      validators: {
        required,
        whiteSpaceValidator,
      },
      customFieldsForm,
      valid,
      loading,
      cardLoading,
      customFields,
      dragOptions,
      edit,
      icons: {
        mdiDotsVertical,
        mdiTrashCanOutline,
        mdiDrag,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
